import React, {useEffect} from 'react';
import "./toast.css"
const Toast = ({show,setShow}) => {
    useEffect(() => {
        show.show &&
        simpleToast()
    }, [show.show]);

    function simpleToast() {
        var x = document.getElementById("simpleToast");
        x.className = "show";
        setTimeout(function(){
            x.className = x.className.replace("show", "");
            setShow({...show,show:false})
        }, 3000);
    }

    return (
        <div className={show.show?"show":""} id="simpleToast">
            <span>{"⚠️"+" "+show.message}</span>
        </div>
    );
};

export default Toast;
